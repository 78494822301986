import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'flowbite-react';
import { FetchWSFields, verifyWorkstreamOwnership } from '../../api/user';
import { useGlobalState } from '../../context/GlobalState';
import TabContent from './tabContent';
import Sidebar from './sidebar';
import NavigationBar from '../../components/navigationBar';
import Checklist from '../../components/form/checklist';

const Dashboard = () => {
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const {
        currentWorkstream,
        setCurrentWorkstream,
        currentEntity,
        setCurrentEntity,
        setAllEntities,
        allWorkstreams,
        setAllWorkstreams,
        triggerNotification,
    } = useGlobalState();
    const { workstreamId } = useParams() || null; // get ID from route

    const [fields, setFields] = useState([]);
    const [tab, setTab] = useState('HOME');
    const [loading, setLoading] = useState(true);

    // Fetch and verify the workstream when the component mounts
    const verifyAndFetchWorkstream = async () => {
        setLoading(true);

        try {
            const response = await verifyWorkstreamOwnership(
                user?.sub.replace('auth0|', ''),
                workstreamId
            );

            // If workstream is valid, update the global state
            setCurrentWorkstream(workstreamId);
            setCurrentEntity(response.entity_id);
            setAllEntities(response.all_entities);
            setAllWorkstreams(response.workstreams);
        } catch (error) {
            console.error(
                'Error in verifyAndFetchWorkstream (Dashboard): ',
                error
            );
            triggerNotification(error.message, 'error');
            navigate('/select');
        }

        setLoading(false);
    };

    // Fetch workstream data when current workstream changes
    const fetchWorkstreamData = async () => {
        if (currentWorkstream) {
            setLoading(true);

            try {
                let response = await FetchWSFields(currentWorkstream); // Fetch fields for the selected workstream
                setFields(response.fields);
            } catch (error) {
                console.error(
                    'Error in fetchWorkstreamData (Dashboard): ',
                    error
                );
                triggerNotification(error.message, 'error');
                navigate('/select');
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        if (user && workstreamId && !currentEntity) {
            verifyAndFetchWorkstream();
        }
    }, [user, workstreamId]);

    useEffect(() => {
        if (currentWorkstream) {
            fetchWorkstreamData();
        }
    }, [currentWorkstream]);

    if (isLoading || loading) {
        return (
            <div className="w-full flex items-center justify-center py-10">
                <Spinner aria-label="Loading spinner" size="xl" />
            </div>
        );
    }

    return (
        <>
            <NavigationBar />
            <Sidebar
                tab={tab}
                setTab={setTab}
                currentWorkstream={currentWorkstream}
            />

            <div className="flex p-4 mt-20 sm:ml-64 w-3/4">
                <TabContent
                    currentTab={tab}
                    isAuthenticated={isAuthenticated}
                    currentWorkstream={currentWorkstream}
                    allWorkstreams={allWorkstreams}
                    fields={fields}
                />
                {currentWorkstream && <Checklist fields={fields} />}
            </div>
        </>
    );
};

export default Dashboard;
