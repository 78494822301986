import React, { useState, useEffect } from 'react';
import {
    db,
    randomDarkColor,
    FormCursors,
    AvatarStack,
} from '../utils/instantDb';
import RenderFields from './form/renderFields';
import { useGlobalState } from '../context/GlobalState';

const RoomPresenceWrapper = ({ room, firstName, userColor, children }) => {
    // Call useSyncPresence directly in a functional component
    room.useSyncPresence({
        name: firstName,
        color: userColor,
    });

    return (
        <FormCursors room={room} children={children} userColor={userColor} />
    );
};

const CoreForm = ({ fields, workstreamId, workstreamName }) => {
    const [userColor] = useState(randomDarkColor());
    const { firstName } = useGlobalState();
    const [room, setRoom] = useState(null);

    useEffect(() => {
        if (workstreamId) {
            const newRoom = db.room('cursors-workstream', workstreamId);
            setRoom(newRoom);
        }
    }, [workstreamId]);

    return (
        <div className="flex-row w-full h-fit justify-center items-center">
            <div className="flex items-center h-fit mb-2">
                <div className="font-literata text-4xl">
                    Let's start your {workstreamName}
                </div>
                <div className="ml-3">
                    {room && <AvatarStack room={room} />}
                </div>
            </div>
            <div className="font-urbanist text-md mb-8">
                Rivet requires you to fill out the following information to get
                your return kicked off.
            </div>

            {/* Cursor sharing functionality */}
            {room ? (
                <RoomPresenceWrapper
                    room={room}
                    firstName={firstName}
                    userColor={userColor}
                >
                    <RenderFields fields={fields} workstreamId={workstreamId} />
                </RoomPresenceWrapper>
            ) : (
                <RenderFields fields={fields} workstreamId={workstreamId} />
            )}
        </div>
    );
};

export default CoreForm;
