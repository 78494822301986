import React from 'react';
import { Button, Spinner } from 'flowbite-react';
import { HiCheckCircle } from 'react-icons/hi';

const FileContent = ({
    type,
    uploadState,
    name,
    alias,
    password,
    onPasswordChange,
    onFileUpload,
    onView,
    onDownload,
}) => {
    if (uploadState === 'NO_FILE') {
        return (
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                    className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                </p>
            </div>
        );
    } else if (uploadState === 'UPLOADING') {
        return <Spinner aria-label="Uploading file" size="xl" />;
    } else if (uploadState === 'PASSWORD_PROTECTED') {
        return (
            <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    The file {name} is password-protected. Please enter the
                    password.
                </p>
                <input
                    type="password"
                    value={password}
                    onChange={onPasswordChange}
                    className="mb-4 p-2 border border-gray-300 rounded"
                    placeholder="Enter password"
                />
                <Button
                    onClick={onFileUpload}
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Submit Password
                </Button>
            </div>
        );
    } else if (uploadState === 'UPLOADED') {
        if (type === 'SINGLE') {
            return (
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                    <HiCheckCircle size={'3rem'} />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        Your file "{alias}" has been uploaded.
                    </p>
                    <div className="flex space-x-4 justify-center">
                        <Button onClick={onView} className="mt-4">
                            View File
                        </Button>
                        <Button onClick={onDownload} className="mt-4">
                            Download
                        </Button>
                    </div>
                </div>
            );
        } else if (type === 'MULTI') {
            return (
                <div className="flex items-center justify-between py-1">
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        File "{alias}" has been uploaded.{' '}
                        <a
                            href="#"
                            className="hover:underline text-cyan-700"
                            onClick={(e) => {
                                onView();
                                e.preventDefault();
                            }}
                        >
                            View
                        </a>{' '}
                        or{' '}
                        <a
                            href="#"
                            className="hover:underline text-cyan-700"
                            onClick={(e) => {
                                onDownload();
                                e.preventDefault();
                            }}
                        >
                            Download
                        </a>
                        .
                    </p>
                </div>
            );
        }
    } else {
        return null;
    }
};

export default FileContent;
