import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { handleFileUpload, fetchFileUrl } from '../../../api/files';
import { FileInput, Label } from 'flowbite-react';
import FilePreview from './filePreview';
import { useGlobalState } from '../../../context/GlobalState';
import FileContent from './fileContent';

const FileUpload = ({ value, fileType, workstreamId }) => {
    const { triggerNotification } = useGlobalState();

    const [file, setFile] = useState(null);
    const [password, setPassword] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadState, setUploadState] = useState(
        value && value !== '' && value !== null ? 'UPLOADED' : 'NO_FILE'
    );

    const [fileMetadata, setFileMetadata] = useState(value);
    const [fileUrl, setFileUrl] = useState('');
    const [numPages, setNumPages] = useState(null);

    const { user } = useAuth0();

    useEffect(() => {
        if (file) {
            handleFileUploadWrapper();
        }
    }, [file]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        if (e.target.files) setUploadState('UPLOADING');
    };

    const handleFileUploadWrapper = async () => {
        try {
            const { status, fileMetadata } = await handleFileUpload(
                file,
                fileType,
                password,
                user?.sub,
                workstreamId
            );

            setUploadState(status);
            setFileMetadata(fileMetadata);
        } catch (error) {
            console.error(
                'Error in handleFileUploadWrapper (FileUpload): ',
                error
            );
            triggerNotification(error.message, 'error');
            setUploadState('NO_FILE');
        }
    };

    const fetchFileUrlWrapper = async (action) => {
        try {
            const fileUrl = await fetchFileUrl(
                fileMetadata?.name,
                workstreamId,
                fileType
            );

            if (action === 'view') {
                setFileUrl(fileUrl);
                setIsModalOpen(true); // Open modal for viewing
            } else if (action === 'download') {
                window.open(fileUrl, '_blank');
            }
        } catch (error) {
            console.error('Error in fetchFileUrlWrapper (fileUpload): ', error);
            triggerNotification(error.message, 'error');
        }
    };

    return (
        <div className="flex flex-col w-full items-center justify-center">
            <Label className="flex h-64 w-full my-4 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <FileContent
                    type={'SINGLE'}
                    uploadState={uploadState}
                    name={fileMetadata?.name}
                    alias={fileMetadata?.alias}
                    password={password}
                    onPasswordChange={(e) => setPassword(e.target.value)}
                    onFileUpload={() => handleFileUploadWrapper()}
                    onView={() => fetchFileUrlWrapper('view')}
                    onDownload={() => fetchFileUrlWrapper('download')}
                />
                <FileInput
                    id={fileType}
                    className="hidden"
                    onChange={handleFileChange}
                />
            </Label>
            <FilePreview
                fileUrl={fileUrl}
                extension={fileMetadata?.extension}
                numPages={numPages}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                isModalOpen={isModalOpen}
                closeModal={() => closeModal()}
            />
        </div>
    );
};

export default FileUpload;
