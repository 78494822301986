import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Navigate,
    Outlet,
} from 'react-router-dom';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { GlobalStateProvider } from './context/GlobalState';
import Home from './screens/home';
import Dashboard from './screens/dashboard/dashboard';
import Selection from './screens/selection';
import NotFound from './screens/notFound';
import NotificationQueue from './components/notifications/notificationQueue';
import './fonts.css';

// Layout component to handle authentication checks
const ProtectedRoute = () => {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>; // You can replace this with a loading spinner
    }

    if (!isAuthenticated) {
        return <Navigate to="/auth" replace />;
    }

    return <Outlet />; // Render child routes if authenticated
};

// Define routes
const router = createBrowserRouter([
    {
        path: '/',
        element: <Navigate to={'/auth'} replace={true} />,
    },
    {
        path: '/auth',
        element: <Home />,
    },
    {
        element: <ProtectedRoute />, // Wrap all protected routes inside this layout
        children: [
            {
                path: '/select',
                element: <Selection />,
            },
            {
                path: '/workstream/:workstreamId',
                element: <Dashboard />,
            },
        ],
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);

const App = () => {
    return (
        <>
            <RouterProvider router={router} />
            <NotificationQueue />
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain="auth.rivet.tax"
        clientId="CkVlAg9zAkVI1KtO8m5aQHJJdoOT7lkA"
        authorizationParams={{
            redirect_uri: window.location.origin + '/select',
        }}
    >
        <GlobalStateProvider>
            <App />
        </GlobalStateProvider>
    </Auth0Provider>
);
