import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FetchUserData } from '../api/user';
import EntitySelector from '../components/selectors/entitySelector';
import WorkstreamSelector from '../components/selectors/workstreamSelector';
import { useGlobalState } from '../context/GlobalState';
import NavigationBar from '../components/navigationBar';
import {
    useHandleEntitySelect,
    useHandleWorkstreamSelect,
} from '../hooks/useSelection';
import { Spinner } from 'flowbite-react';

const Selection = () => {
    const { user } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [firstName, setFirstName] = useState('');
    const { currentEntity, allEntities, setAllEntities, allWorkstreams } =
        useGlobalState();

    const handleEntitySelect = useHandleEntitySelect();
    const handleWorkstreamSelect = useHandleWorkstreamSelect();

    const fetchData = async () => {
        setLoading(true);
        if (user) {
            const userId = user?.sub.replace('auth0|', '');

            let data = await FetchUserData(userId);
            setFirstName(data?.first_name || '');
            setAllEntities(data?.entities || []);

            if (data?.entities?.length === 1) {
                handleEntitySelect(userId, data?.entities[0].entityId);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [user]);

    useEffect(() => {
        setLoading(true);
    }, []);

    return (
        <>
            {loading ? (
                <div className="w-full flex items-center justify-center">
                    <Spinner aria-label="Loading spinner" size="xl" />
                </div>
            ) : (
                <>
                    <NavigationBar />
                    <div className="flex flex-col items-center justify-center w-full">
                        {!currentEntity ? (
                            <EntitySelector
                                userId={user?.sub.replace('auth0|', '')}
                                entities={allEntities}
                                firstName={firstName}
                                setLoading={setLoading}
                                onSelect={handleEntitySelect}
                            />
                        ) : (
                            <WorkstreamSelector
                                workstreams={allWorkstreams}
                                firstName={firstName}
                                setLoading={setLoading}
                                onSelect={handleWorkstreamSelect}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default Selection;
