import axios from 'axios';
import baseURL from './baseURL';

export const handleFileUpload = async (
    file,
    fileType,
    password,
    userId,
    workstreamId
) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
        const params = new URLSearchParams({
            id: userId.replace('auth0|', ''),
            file_type: fileType,
            ws_id: workstreamId || '',
        });

        if (password) {
            params.append('password', password);
        }

        const uploadURL = `${baseURL}/file-upload?${params.toString()}`;

        const response = await axios.post(uploadURL, formData);

        // assume default state to return is ERROR
        let status = 'ERROR';
        let fileMetadata = null;

        if (response.data.status === 'PASSWORD_PROTECTED') {
            status = 'PASSWORD_PROTECTED';
        } else if (response.data.status === 'success') {
            status = 'UPLOADED';
            fileMetadata = response.data.file_metadata;
        }

        return { status, fileMetadata };
    } catch (error) {
        console.error('Error in handleFileUpload: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error uploading file!'
        );
    }
};

export const fetchFileUrl = async (filename, workstreamId, fileType) => {
    try {
        const params = new URLSearchParams({
            filename,
            ws_id: workstreamId,
            file_type: fileType,
        });

        const response = await axios.get(
            `${baseURL}/access-file?${params.toString()}`
        );

        return response?.data?.url;
    } catch (error) {
        console.error('Error in fetchFileUrl: ', error);
        throw new Error(
            error?.response?.data?.message || 'Error fetching file URL!'
        );
    }
};
