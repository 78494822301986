import React, { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { handleFileUpload, fetchFileUrl } from '../../../api/files';
import { FileInput, Label } from 'flowbite-react';
import FilePreview from './filePreview';
import { useGlobalState } from '../../../context/GlobalState';
import FileContent from './fileContent';

const MultiFileUpload = ({ value = [], file_type, workstreamId }) => {
    const { triggerNotification } = useGlobalState();

    // Add a reference to the file input
    const fileInputRef = useRef(null);

    const [files, setFiles] = useState(value.map(() => 'GET_URL'));
    const [fileMetadata, setFileMetadata] = useState(value);
    const [uploadStates, setUploadStates] = useState(
        value.map(() => 'UPLOADED')
    );
    const [passwords, setPasswords] = useState(Array(value.length).fill(''));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    const { user } = useAuth0();

    useEffect(() => {
        const index = uploadStates.indexOf('UPLOADING');

        if (index !== -1) {
            handleFileUploadWrapper(index);
        }
    }, [files, uploadStates]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = (e) => {
        const newFiles = Array.from(e.target.files);
        if (newFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            setUploadStates((prevStates) => [
                ...prevStates,
                ...newFiles.map(() => 'UPLOADING'),
            ]);
        }
    };

    const handlePasswordChange = (index, value) => {
        setPasswords((prevPasswords) => {
            const updatedPasswords = [...prevPasswords];
            updatedPasswords[index] = value;
            return updatedPasswords;
        });
    };

    const handleFileUploadWrapper = async (index) => {
        try {
            const { status, fileMetadata } = await handleFileUpload(
                files[index],
                file_type,
                passwords[index],
                user?.sub,
                workstreamId
            );

            if (status === 'UPLOADED') {
                setFileMetadata((prevMetadata) => {
                    const updatedMetadata = [...prevMetadata];
                    updatedMetadata[index] = fileMetadata;
                    return updatedMetadata;
                });
            }

            setUploadStates((prevStates) => {
                const updatedStates = [...prevStates];
                updatedStates[index] = status;
                return updatedStates;
            });
        } catch (error) {
            console.error(
                'Error in handleFileUploadWrapper (multiFileUpload): ',
                error
            );
            triggerNotification(error.message, 'error');

            // Remove the failed upload from the list of files
            setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            setUploadStates((prevStates) =>
                prevStates.filter((_, i) => i !== index)
            );

            // Clear the file input so the user can re-upload the same file
            if (fileInputRef.current) {
                fileInputRef.current.value = null;
            }
        }
    };

    const fetchFileUrlWrapper = async (index, action) => {
        try {
            const fileUrl = await fetchFileUrl(
                fileMetadata[index].name,
                workstreamId,
                file_type
            );

            setFileUrl(fileUrl);
            setSelectedFile(fileMetadata[index]);

            if (action === 'view') {
                setIsModalOpen(true); // Open modal for viewing
            } else if (action === 'download') {
                window.open(fileUrl, '_blank');
            }
        } catch (error) {
            console.error(
                'Error in fetchFileUrlWrapper (multiFileUpload): ',
                error
            );
            triggerNotification(error.message, 'error');
        }
    };

    return (
        <div className="flex flex-col w-full items-center justify-center">
            <Label className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                <div className="flex flex-col items-center overflow-y-auto px-8 py-4">
                    {files.length === 0 && (
                        <div>
                            <FileContent uploadState={'NO_FILE'} />
                        </div>
                    )}

                    {files.length !== 0 && (
                        <div className="flex flex-col items-start w-full">
                            {files.map((file, index) => (
                                <div key={index} className="w-full">
                                    <FileContent
                                        type={'MULTI'}
                                        uploadState={uploadStates[index]}
                                        name={files[index]?.name}
                                        alias={fileMetadata[index]?.alias}
                                        password={passwords[index]}
                                        onPasswordChange={(e) =>
                                            handlePasswordChange(
                                                index,
                                                e.target.value
                                            )
                                        }
                                        onFileUpload={() =>
                                            handleFileUploadWrapper(index)
                                        }
                                        onView={() =>
                                            fetchFileUrlWrapper(index, 'view')
                                        }
                                        onDownload={() =>
                                            fetchFileUrlWrapper(
                                                index,
                                                'download'
                                            )
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <FileInput
                    ref={fileInputRef}
                    id={file_type}
                    className="hidden"
                    onChange={handleFileChange}
                    multiple
                />
            </Label>
            <FilePreview
                fileUrl={fileUrl}
                extension={selectedFile?.extension}
                numPages={numPages}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                isModalOpen={isModalOpen}
                closeModal={() => closeModal()}
            />
        </div>
    );
};

export default MultiFileUpload;
