import { Checkbox, Label } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateMultiSelectValues } from '../../api/update';
import { useGlobalState } from '../../context/GlobalState';

const MultiSelect = ({
    workstreamId,
    options,
    values,
    form,
    subForm = null,
}) => {
    let [selectValues, setSelectValues] = useState(values);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const { triggerNotification } = useGlobalState();

    const handleSelectChange = async (e) => {
        let newSelectValues = [...selectValues];
        newSelectValues[options.indexOf(e.target.id)] = e.target.checked
            ? '1'
            : '0';
        setSelectValues(newSelectValues);

        try {
            await updateMultiSelectValues(
                user?.sub,
                workstreamId,
                form,
                subForm,
                newSelectValues
            );
        } catch (error) {
            console.error('Error in handleSelectChange: ', error);
            triggerNotification(error.message, 'error');
        }
    };

    return (
        <div
            className="flex w-full justify-center flex-col gap-4"
            id="checkbox"
        >
            {selectValues
                ? options.map((option, index) => {
                      return (
                          <div key={option} className="flex items-center gap-2">
                              <Checkbox
                                  id={option}
                                  onChange={handleSelectChange}
                                  checked={selectValues[index] == '1'}
                              />
                              <Label htmlFor={option}>{option}</Label>
                          </div>
                      );
                  })
                : null}
        </div>
    );
};

export default MultiSelect;
