import { Label, Textarea } from 'flowbite-react';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { updateShortFormValue } from '../../api/update';
import { useGlobalState } from '../../context/GlobalState';
import { db } from '../../utils/instantDb';

const ShortForm = ({
    workstreamId,
    name,
    value,
    form,
    subForm,
    typingIndicatorName,
}) => {
    let [sfValue, setSfValue] = useState(value);
    const { user } = useAuth0();
    const { triggerNotification } = useGlobalState();

    // Initialize room for typing indicator and track local typing activity
    const room = db.room('typing-indicator', workstreamId);
    const { inputProps } = room.useTypingIndicator(typingIndicatorName);

    const handleSfChange = async (e) => {
        let newSfValue = e.target.value;
        setSfValue(newSfValue);

        try {
            await updateShortFormValue(
                user?.sub,
                workstreamId,
                form,
                subForm,
                newSfValue
            );
        } catch (error) {
            console.error('Error in handleSfChange (ShortForm): ', error);
            triggerNotification(error.message, 'error');
        }
    };

    return (
        <div className="flex w-full m-4">
            <Label htmlFor="comment" value={name} />
            <Textarea
                placeholder="Enter your response here"
                value={sfValue}
                onChange={handleSfChange}
                onKeyDown={inputProps.onKeyDown}
                onBlur={inputProps.onBlur}
                required
                rows={1}
            />
        </div>
    );
};

export default ShortForm;
