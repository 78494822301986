import React, { createContext, useState, useContext } from 'react';

// Create a context
const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
    const [firstName, setFirstName] = useState('Unknown User');

    const [currentEntity, setCurrentEntity] = useState(null);
    const [currentWorkstream, setCurrentWorkstream] = useState(null);
    const [allEntities, setAllEntities] = useState([]);
    const [allWorkstreams, setAllWorkstreams] = useState([]);

    // Notification queue management (for all types of notifications)
    const [notificationQueue, setNotificationQueue] = useState([]);

    const triggerNotification = (message, type) => {
        // Add the new notification to the queue with a unique ID (timestamp)
        setNotificationQueue((prevQueue) => [
            ...prevQueue,
            { message, type, id: Date.now() },
        ]);
    };

    const dismissNotification = (id) => {
        setNotificationQueue((prevQueue) =>
            prevQueue.filter((notif) => notif.id !== id)
        );
    };

    return (
        <GlobalStateContext.Provider
            value={{
                firstName,
                setFirstName,
                currentEntity,
                setCurrentEntity,
                currentWorkstream,
                setCurrentWorkstream,
                allEntities,
                setAllEntities,
                allWorkstreams,
                setAllWorkstreams,
                notificationQueue,
                triggerNotification,
                dismissNotification,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

// Custom hook to use the GlobalState
export const useGlobalState = () => useContext(GlobalStateContext);
